import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import accountlinkConsentMessages from '../i18n/accountlinkConsent.messages';
import { AuthToken } from '../utils/authToken';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { GandalfUser } from '../types/auth';
import { AppConfig } from '../types/app';

export const ICANT_BUTTON_DATA_ID = 'al_icant';

export const AccountLinkICantButton = ({
  authToken,
  onCantClicked,
  user,
  restrictIcantFlow,
  config,
}: {
  authToken: AuthToken;
  onCantClicked: () => void;
  user?: GandalfUser;
  restrictIcantFlow?: () => void;
  config: AppConfig;
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const initiateICantFlow = () => {
    emitAccountLinkingRUMEvents(
      authToken.jwtToken,
      'confirm_existing_profile_i_cant_button'
    );

    onCantClicked();
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (
            config.enableRestricAccountLinking === 'true' &&
            user?.organization !== undefined
          ) {
            const skipLinking = checkIfSkipIsAllowed(user);
            skipLinking
              ? initiateICantFlow()
              : restrictIcantFlow && restrictIcantFlow();
            return;
          }
          initiateICantFlow();
        }}
        data-testid={ICANT_BUTTON_DATA_ID}
      >
        {formatMessage(
          accountlinkConsentMessages.cantConfirmExistingProfileOwnershipButton
        )}
      </Button>
    </>
  );
};

export function checkIfSkipIsAllowed(user: GandalfUser | undefined): boolean {
  const userOrgId = user?.organization?.id;
  const allowedOrgs = user?.associate_to?.OrganizationIds;
  let isUserOrgAllowed: boolean = false;

  if (userOrgId) {
    if (Array.isArray(allowedOrgs)) {
      isUserOrgAllowed = allowedOrgs.includes(userOrgId);
    } else if (typeof allowedOrgs === 'string') {
      isUserOrgAllowed = allowedOrgs === userOrgId;
    }
    //if userOrgAllowed, then can't skip linking
    return !isUserOrgAllowed;
  }
  return true;
}
