import { VIBE_ATTR_PATH } from '../constants/api';
import { USER_MERGE_CONSENTED_GANDALF_PROFILE_FIELD } from '../constants/auth';
import { UPDATE_USER_MERGE_CONSENTED } from '../constants/metrics';
import { GandalfUser } from '../types/auth';
import { request, joinUrlPath } from '../utils/apiHelper';

export async function handleMerge(
  gandalfProfileEndpoint: string,
  user: GandalfUser,
  userMergeConsented: string
) {
  if (!user.jwtToken) {
    throw new Error('User token not defined');
  }
  const opts: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.jwtToken,
    },
    body: JSON.stringify({
      attributes: [
        {
          name: USER_MERGE_CONSENTED_GANDALF_PROFILE_FIELD,
          attributeValue: {
            S: userMergeConsented,
          },
        },
      ],
    }),
  };
  return request(joinUrlPath(gandalfProfileEndpoint, VIBE_ATTR_PATH), opts, {
    metricNs: UPDATE_USER_MERGE_CONSENTED,
  }).then((response) => response.json());
}
