import { CONSENT_PATH } from '../constants/api';
import { UPDATE_CONSENT } from '../constants/metrics';
import { GandalfUser } from '../types/auth';
import { request, joinUrlPath } from '../utils/apiHelper';

export interface Consent {
  consentStatus: ConsentStatus;
}

export enum ConsentStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export async function postConsent(
  gandalfProfileEndpoint: string,
  consentGranted: boolean,
  user: GandalfUser
) {
  if (!user.jwtToken) {
    throw new Error('User toke not defined');
  }

  const consent: Consent = {
    consentStatus: consentGranted
      ? ConsentStatus.ACCEPTED
      : ConsentStatus.REJECTED,
  };
  const opts: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.jwtToken,
    },
    body: JSON.stringify({
      consent: consent,
    }),
  };
  return request(joinUrlPath(gandalfProfileEndpoint, CONSENT_PATH), opts, {
    metricNs: UPDATE_CONSENT,
  }).then((response) => response.json());
}
