import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import { postConsent } from '../services/consent';
import { AppConfig } from '../types/app';
import { GandalfUser } from '../types/auth';

export const AcceptConsent = ({
  onContinue,
  buttonLabel,
  config,
  user,
  setConsentAccepted,
}: {
  onContinue: () => void;
  buttonLabel: string;
  config: AppConfig;
  user: GandalfUser;
  setConsentAccepted: (isAccepted: boolean) => void;
}) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        handleAcceptConsent(config, user);
        setConsentAccepted(true);
        onContinue();
      }}
      variant="primary"
      data-testid={'accept-consent'}
    >
      {buttonLabel}
    </Button>
  );
};

const handleAcceptConsent = async (config: AppConfig, user: GandalfUser) => {
  await postConsent(config.gandalfProfileEndpoint, true, user);
};
