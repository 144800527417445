import { useState, useEffect, useCallback } from 'react';
import { Hub, HubCallback, HubCapsule } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { AmplifyAuthEvent, GandalfUser } from '../types/auth';
import useIsMounted from './useIsMounted';
import logger from '../utils/logger';
import { CustomCognitoUser } from '../types/amplifyAuthExtensions';
import UnauthorizedError from '../errors/UnauthorizedError';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import MissingEmailError from '../errors/MissingEmailError';
import { emailExistsInForbiddenError } from '../utils/errorHelper';
import { getAmplifyConfig } from '../components/postAuth/ValidateAccountLink';
import { AppConfig } from '../types/app';
import { FORBIDDEN_FAILED_STATUS } from '../constants/auth';

let initAccountLinkAmplifyConfig: any;

const getVibeId = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.vibe_user_id;
};

const getHatId = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.hat_id;
};

const getName = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.name;
};

const getProviderName = (user: CustomCognitoUser) => {
  // We only expect to ever find one identity in the array which is why we use the item.
  return user?.signInUserSession?.idToken?.payload?.public_provider_name;
};

const getEulaStatus = (
  user: CustomCognitoUser,
  eulaAcceptanceAttribute: string
) => {
  return (user?.signInUserSession?.idToken?.payload as Record<string, any>)[
    eulaAcceptanceAttribute
  ] as string | undefined;
};

const getJwtToken = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.jwtToken;
};

const getEmail = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.email;
};

const getNewUser = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.new_user;
};

const getAssociate_to = (user: CustomCognitoUser) => {
  const associateTo = user?.signInUserSession?.idToken?.payload?.associate_to;
  return associateTo ? JSON.parse(associateTo) : undefined;
};

const getInvation = (user: CustomCognitoUser) => {
  const invitation = user?.signInUserSession?.idToken?.payload?.invitation;
  return invitation ? JSON.parse(invitation) : undefined;
};

const getFailedAssociate = (user: CustomCognitoUser) => {
  const failedAssociate =
    user?.signInUserSession?.idToken?.payload?.failed_associate;
  return failedAssociate ? JSON.parse(failedAssociate) : undefined;
};

const getCreatedOn = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.created_on;
};

const getEmailVerified = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.email_verified;
};

const getEmailVerificationRequiredFlag = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.email_verification_required;
};

const getOrganizationDetails = (user: CustomCognitoUser) => {
  const orgDetails = user?.signInUserSession?.idToken?.payload?.organization;
  return orgDetails ? JSON.parse(orgDetails) : undefined;
};

const getProfileDeactivatedFlag = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.deactivated;
};

const getSignInSwitchNeeded = (user: CustomCognitoUser) => {
  return user?.signInUserSession?.idToken?.payload?.signin_switch_needed;
};

const useHubListener = (channel: string, callback: HubCallback) => {
  useEffect(() => {
    if (initAccountLinkAmplifyConfig) {
      Auth.configure(initAccountLinkAmplifyConfig.Auth);
    }
    Hub.listen(channel, callback);
    return () => {
      Hub.remove(channel, callback);
    };
  }, [channel, callback]);
};

export const accountLinkingInit = (
  configureAuthAccountLink: boolean,
  config: AppConfig
) => {
  if (configureAuthAccountLink === true && config) {
    const amplifyConfig = getAmplifyConfig(config);
    initAccountLinkAmplifyConfig = amplifyConfig;
    Auth.configure(amplifyConfig.Auth);
  }
};

const useGandalfPostAuth = (
  configureAuthAccountLink?: boolean,
  config?: AppConfig
) => {
  if (configureAuthAccountLink === true && config) {
    const amplifyConfig = getAmplifyConfig(config);
    Auth.configure(amplifyConfig.Auth);
  }
  const isMounted = useIsMounted();
  const [user, setUser] = useState<GandalfUser | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [customState, setCustomState] = useState<string | undefined>();
  const [receivedNonce, setReceivedAuthNonce] = useState<string | undefined>();
  const stateStorage = useStateStorage();
  const getUser = useCallback(() => {
    return Auth.currentAuthenticatedUser().then((userData) => {
      if (!isMounted) return;
      setUser({
        vibeId: getVibeId(userData),
        hatId: getHatId(userData),
        providerName: getProviderName(userData),
        name: getName(userData),
        eulaStatus: config?.eulaAcceptanceAttributeName
          ? getEulaStatus(userData, config?.eulaAcceptanceAttributeName)
          : 'false',
        jwtToken: getJwtToken(userData),
        email: getEmail(userData),
        associate_to: getAssociate_to(userData),
        failed_associate: getFailedAssociate(userData),
        new_user: getNewUser(userData),
        created_on: getCreatedOn(userData),
        email_verified: getEmailVerified(userData),
        invitation: getInvation(userData),
        email_verification_required: getEmailVerificationRequiredFlag(userData),
        organization: getOrganizationDetails(userData),
        deactivated: getProfileDeactivatedFlag(userData),
        signin_switch_needed: getSignInSwitchNeeded(userData),
      });
    });
  }, [isMounted, config]);
  const handleAuthEvent = useCallback(
    ({ payload: { event, data } }: HubCapsule) => {
      if (!isMounted) return;
      switch (event) {
        case AmplifyAuthEvent.signIn:
        case AmplifyAuthEvent.cognitoHostedUI:
          getUser().catch((error) => {
            setError(error);
            logger.debug(
              'Get user failed after getting signIn Hub event.',
              data
            );
          });
          break;
        case AmplifyAuthEvent.customOAuthState:
          try {
            setReceivedAuthNonce(data);
            const value = stateStorage.getItem(data);
            stateStorage.removeItem(data);
            setCustomState(value ?? undefined);
          } catch (signInError) {
            setError(signInError as Error);
          }
          break;
        case AmplifyAuthEvent.signOut:
          setUser(null);
          break;
        case AmplifyAuthEvent.signIn_failure:
        case AmplifyAuthEvent.cognitoHostedUI_failure:
        case AmplifyAuthEvent.customState_failure:
          const errorMsg = data?.message || 'Unknown error.';
          if (errorMsg.includes(FORBIDDEN_FAILED_STATUS)) {
            if (!emailExistsInForbiddenError(errorMsg)) {
              setError(new MissingEmailError(errorMsg));
            } else {
              setError(new UnauthorizedError(errorMsg));
            }
          } else {
            setError(new Error(errorMsg));
          }
          logger.debug('Sign in failed: ', data);
          break;
        default:
          break;
      }
    },
    [getUser, isMounted, stateStorage]
  );

  useHubListener('auth', handleAuthEvent);
  return { user, error, customState, receivedNonce };
};

export default useGandalfPostAuth;
