import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { useNonceGenerator } from '../../contexts/NonceGeneratorContextProvider';
import { buildNonce } from '../../services/auth';
import { PostAuthComponentParams } from '../../types/auth';
import { AuthToken, deriveOtpUrl, storeAuthToken } from '../../utils/authToken';
import logger from '../../utils/logger';
import {
  IDP_URL_PARAM,
  REQUIRE_EMAIL_VERIFICATION,
} from '../../constants/auth';
import useVibeAttributes from '../../hooks/useVibeAttributes';

export const VERIFY_EMAIL_COOKIE_NAME = 'VerifyEmail';
export const VERIFY_EMAIL_COOKIE_EXPIRATION_MS = 1 * 60 * 1000; // 1 minute

const VerifyEmail = ({
  user,
  onContinue,
  onCancel,
  config,
}: PostAuthComponentParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const nonceGenerator = useNonceGenerator();

  const { needsVerification, error } = useVibeAttributes(config, user);

  if (error !== undefined) {
    logger.error(`Error fetching attributes: ${error}`);
    throw new Error('Failed getting Vibe Attributes');
  }

  const isRequiredToEmailVerifyFromToken =
    user.email_verification_required === true;

  const params = new URLSearchParams(window.location.search);

  const isRequestToEmailVerifyFromUrl =
    params.get(REQUIRE_EMAIL_VERIFICATION) === 'true';

  useEffect(() => {
    if (config.enableEmailVerification !== 'true') return onContinue();
    if (
      !isRequestToEmailVerifyFromUrl &&
      needsVerification === false &&
      !isRequiredToEmailVerifyFromToken
    ) {
      onContinue();
    } else if (!user.jwtToken) {
      logger.debug('User is missing a JWT token, aborting the sign-in');
      throw new Error('Token not found');
    } else if (!user.email) {
      logger.debug('User has no email, nothing to verify');
      // Note: it is up to each LXP to display an appropriate error message here if needed
      onContinue();
    } else if (
      params.get(REQUIRE_EMAIL_VERIFICATION) === 'true' ||
      needsVerification === true ||
      user.email_verification_required === true
    ) {
      // store current auth token
      const nonce = buildNonce(nonceGenerator);
      params.set(IDP_URL_PARAM, user.providerName);
      const authToken: AuthToken = {
        originalRequestURL: params.toString(),
        jwtToken: user.jwtToken ?? '',
        nonce,
      };
      storeAuthToken(authToken);

      // create cookie to indicate that OTP email should immediately be sent
      Cookies.set(VERIFY_EMAIL_COOKIE_NAME, user.email, {
        expires: new Date(
          new Date().getTime() + VERIFY_EMAIL_COOKIE_EXPIRATION_MS
        ),
        sameSite: 'Strict',
        secure: true,
      });

      // redirect to OTP IDP
      const redirect_url = `${window.location.origin}/verifyemails`;
      const otpUrl = deriveOtpUrl(config, nonce, redirect_url);
      window.location.assign(otpUrl);
    }
    // eslint-disable-next-line
  }, [
    config,
    nonceGenerator,
    onContinue,
    user,
    needsVerification,
    isRequestToEmailVerifyFromUrl,
    isRequiredToEmailVerifyFromToken,
  ]);

  useEffect(() => {
    if (needsVerification !== undefined) {
      setIsLoading(false);
    }
  }, [needsVerification]);

  return isLoading ? null : <></>;
};

export default VerifyEmail;
