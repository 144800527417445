import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import { AreYouSureConsentModal } from './AreYouSureConsentModal';
import { GandalfUser } from '../types/auth';
import { AppConfig } from '../types/app';

const DenyConsent = ({
  onContinue,
  buttonLabel,
  user,
  onCancel,
  config,
}: {
  onContinue: () => void;
  buttonLabel: string;
  user: GandalfUser;
  onCancel: () => void;
  config: AppConfig;
}) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
        data-testid={'deny-consent'}
      >
        {buttonLabel}
      </Button>
      {visible ? (
        <AreYouSureConsentModal
          user={user}
          onContinue={onContinue}
          onCancel={onCancel}
          config={config}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DenyConsent;
