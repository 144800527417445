import { defineMessages } from 'react-intl';

const signInSwitchNeededMessages = defineMessages({
  header: {
    id: 'SignInSwitchNeeded.header',
    defaultMessage: 'Sign in to {orgName}',
    description: 'Sign in to your account',
  },
  containerTitle: {
    id: 'SignInSwitchNeeded.containerTitle',
    defaultMessage: 'Sign-in method has changed',
    description: 'Sign-in method has changed',
  },
  containerBody: {
    id: 'SignInSwitchNeeded.containerBody',
    defaultMessage:
      'Your organization is now using {idpToUse}. Contact your learning admin if you need a new invitation or customer service for further support',
    description: 'Sign-in method has changed description',
  },
  returnToSignInPageButton: {
    id: 'SignInSwitchNeeded.returnToSignInPageButton',
    defaultMessage: 'Return to Sign-in page',
    description: 'Return to sign in page',
  },
  continueButton: {
    id: 'SignInSwitchNeeded.continueButton',
    defaultMessage: 'Continue with {idpToUse}',
    description: 'Continue',
  },
  singleSignOn: {
    id: 'SignInSwitchNeeded.singleSignOn',
    defaultMessage: 'single sign-on',
    description: 'single sign-on',
  },
  organization: {
    id: 'SignInSwitchNeeded.Organization',
    defaultMessage: 'Organization',
    description: 'Organization',
  },
});

export default signInSwitchNeededMessages;
