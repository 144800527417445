import { useEffect, useState } from 'react';
import { AppConfig } from '../types/app';
import { GandalfUser } from '../types/auth';
import {
  BE_TO_FE_ATTR_MAPPING,
  USER_MERGE_CONSENT_REQUIRED,
  VIBE_ATTR_PATH,
} from '../constants/api';
import { CHECK_USER_MERGE_CONSENT_REQUIRED } from '../constants/metrics';
import { request, joinUrlPath } from '../utils/apiHelper';

const useVibeAttributesForMerge = (config: AppConfig, user: GandalfUser) => {
  const [userMergeConsentRequired, setUserMergeConsentRequired] = useState<
    string | undefined
  >(undefined);
  const [error, setError] = useState<any | undefined>(undefined);

  useEffect(() => {
    const fetchAttributes = async () => {
      const userHasEmail = user?.email !== undefined && user?.email !== '';
      if (
        config.enableLearnerConsentOnMerge3pPostAuthFlow === 'true' &&
        userHasEmail
      ) {
        try {
          const resp = await vibeGetAttributes(config, user);

          let profileMap: Map<string, string> = new Map();
          if (resp.returnItems) {
            resp.returnItems.forEach((attribute: any) => {
              const supportedAttr = BE_TO_FE_ATTR_MAPPING.get(attribute.name);
              if (supportedAttr !== undefined) {
                profileMap.set(supportedAttr, attribute.value.S);
              }
            });
          }
          if (
            profileMap.get(USER_MERGE_CONSENT_REQUIRED) !== undefined &&
            profileMap.get(USER_MERGE_CONSENT_REQUIRED) !== ''
          ) {
            setUserMergeConsentRequired(
              profileMap.get(USER_MERGE_CONSENT_REQUIRED)
            );
          } else {
            setUserMergeConsentRequired(undefined);
          }
        } catch (error: any) {
          setError(error);
        }
      } else {
        setUserMergeConsentRequired(undefined);
      }
    };
    fetchAttributes();
  }, [config, user?.user_merge_consented, user]);

  return { userMergeConsentRequired, error };
};

export const vibeGetAttributes = async (
  config: AppConfig,
  user: GandalfUser
) => {
  if (!user.jwtToken) {
    throw new Error('User token not defined');
  }

  const opts: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.jwtToken,
    },
  };
  const resp = await request(
    joinUrlPath(config.gandalfProfileEndpoint, VIBE_ATTR_PATH),
    opts,
    {
      metricNs: CHECK_USER_MERGE_CONSENT_REQUIRED,
    }
  );

  return resp.json();
};

export default useVibeAttributesForMerge;
