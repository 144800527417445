import React, { JSX } from 'react';
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';

const RestrictIcantFlowComponent = (): JSX.Element => {
  const [visible, setVisible] = React.useState(true);
  if (visible) {
    return (
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(false);
                }}
              >
                Okay
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Cannot skip linking the account"
      >
        {/* Note this is temporary placeholder till UX is complete */}
        <TextContent>
          In order to continue you must complete the account link. If you cannot
          link your account please contact customer service
        </TextContent>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default RestrictIcantFlowComponent;
