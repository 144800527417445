import { Form, SpaceBetween } from '@amzn/awsui-components-react';
import { PostAuthComponentParams } from '../../types/auth';
import React, { useEffect } from 'react';
import ConsentContainer from '../ConsentContainer';
import { AcceptConsent } from '../AcceptConsent';
import DenyConsent from '../DenyConsent';
import IndetityConsentInfo from '../IdentityConsentInfo';
import { useIntl } from 'react-intl';
import identityConsentMessages from '../../i18n/identityConsent.messages';

const IdentityConsent = ({
  user,
  onContinue,
  onCancel,
  config,
  setConsetAccepted,
}: PostAuthComponentParams): JSX.Element => {
  const { formatMessage } = useIntl();
  const shouldShowConsentPage = () => {
    return (
      config.show_invitation_screen === 'true' &&
      user.invitation !== undefined &&
      user.invitation.learner_data_consent_required === 'true'
    );
  };

  useEffect(() => {
    const showConsentPage = shouldShowConsentPage();
    if (!showConsentPage) {
      onContinue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onContinue]);

  if (!shouldShowConsentPage()) return <></>;

  return (
    <>
      <ConsentContainer config={config} user={user}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <DenyConsent
                buttonLabel={formatMessage(
                  identityConsentMessages.doNotJoinOrganization
                )}
                onContinue={onContinue}
                onCancel={onCancel}
                user={user}
                config={config}
              />
              <AcceptConsent
                onContinue={onContinue}
                buttonLabel={formatMessage(
                  identityConsentMessages.acceptButton
                )}
                config={config}
                user={user}
                setConsentAccepted={setConsetAccepted}
              />
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <IndetityConsentInfo user={user} />
          </SpaceBetween>
        </Form>
      </ConsentContainer>
    </>
  );
};

export default IdentityConsent;
