import { AppConfig } from './app';

export type IdProvider = {
  idp: string;
  url: string;
  lastUsedIdpDisplayName?: string;
  userPoolId?: string;
  clientId?: string;
};

export type GandalfUser = {
  vibeId: string;
  hatId: string;
  providerName: string;
  name?: string;
  eulaStatus?: string;
  jwtToken?: string;
  email?: string;
  nonce?: string;
  associate_to?: Associate_to;
  failed_associate?: FailedAssociate;
  new_user?: boolean | string;
  created_on?: number;
  email_verified?: boolean;
  user_merge_consented?: boolean;
  invitation?: Invitation;
  email_verification_required?: boolean;
  organization?: Organization;
  deactivated?: boolean;
  signin_switch_needed?: string;
};

export type AuthToken = {
  originalRequestURL: string;
  jwtToken: string;
  nonce: string;
};

export type PostAuthComponentParams = {
  user: GandalfUser;
  onContinue: () => void;
  onCancel: () => void;
  config: AppConfig;
  error?: Error;
  setError: (error: any) => void;
  consentAccepted: boolean | undefined;
  setConsetAccepted: (isAccepted: boolean) => void;
};

export enum AmplifyAuthEvent {
  signIn = 'signIn',
  cognitoHostedUI = 'cognitoHostedUI',
  customOAuthState = 'customOAuthState',
  signOut = 'signOut',
  signIn_failure = 'signIn_failure',
  cognitoHostedUI_failure = 'cognitoHostedUI_failure',
  customState_failure = 'customState_failure',
}

export enum ExclusivityType {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
}

export type Associate_to = {
  HatId: string[] | string;
  VibeId: string[] | string;
  Exclusivity: ExclusivityType;
  LinkedToIDP: string[] | string;
  LinkedToLastSignedIn: string[] | string;
  OrganizationIds?: string[] | string;
};

export type Invitation = {
  id: string;
  is_restricted_org: boolean;
  invitationUserID: string;
  organization_name: string;
  learner_data_consent_required: string;
};

export type Organization = {
  name: string;
  id: string;
  is_restricted: string;
};

export type FailedAssociationEntry = {
  IDP: string;
  LastSignedIn: number;
};

export type FailedAssociate = {
  FailedAssociations: FailedAssociationEntry[];
};
