import { defineMessages } from 'react-intl';

const profileDeactivatedMessages = defineMessages({
  header: {
    id: 'profileDeactivated.header',
    defaultMessage: 'Join {orgName} ',
    description: 'Header of the identity consent section',
  },
  returnToSignInButton: {
    id: 'profileDeactivated.returnToSignInButton',
    defaultMessage: 'Return to sign in',
    description: 'Button to return to sign in page',
  },
  containerTitle: {
    id: 'profileDeactivated.containerTitle',
    defaultMessage: 'Profile removed',
    description: 'Profile has been removed title',
  },
  description: {
    id: 'profileDeactivated.description',
    defaultMessage:
      'You no longer have a profile with this organization. Contact your learner admin to get a new invitation.',
    description: 'Profile has been removed description',
  },
  personalBuilderIdRecommendation: {
    id: 'profileDeactivated.personalBuilderIdRecommendation',
    defaultMessage:
      'If you want to take training on your own, you can sign in or sign up using your personal AWS Builder ID.',
    description:
      'If you want to take training on your own, you can sign in or sign up using your personal AWS Builder ID.',
  },
  organizationLabel: {
    id: 'profileDeactivated.organizationLabel',
    defaultMessage: 'organization',
    description: 'Organization label',
  },
});

export default profileDeactivatedMessages;
