import { PostAuthComponentParams } from '../../types/auth';
import React, { useEffect, useState } from 'react';
import Main from '../Main';
import ContainerLayout from '../ContainerLayout';
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import signInSwitchNeededMessages from '../../i18n/signInSwitchNeeded.messages';
import { useAmplifyConfig } from '../../hooks/useAmplifyConfig';
import { useStateStorage } from '../../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../../contexts/NonceGeneratorContextProvider';
import { startAuthFlow } from '../../services/auth';
import { transformNameToIdProvider } from '../../services/providers';

const SignInSwitchNeeded = ({
  user,
  onContinue,
  onCancel,
  config,
}: PostAuthComponentParams) => {
  const [signInSwitchFlowEnabled, setEnableSignInSwitch] =
    useState<boolean>(false);

  const { formatMessage } = useIntl();

  useAmplifyConfig(config);

  const stateStorage = useStateStorage();

  const nonceGenerator = useNonceGenerator();

  const idpToUse = user.signin_switch_needed;

  useEffect(() => {
    if (config.enableSignInSwitch === 'true' && idpToUse) {
      setEnableSignInSwitch(true);
    } else {
      onContinue();
    }
  }, [config.enableSignInSwitch, idpToUse, onContinue]);

  function handleContinueToIdp() {
    if (idpToUse) {
      const provider = transformNameToIdProvider(
        idpToUse,
        config.gandalfDomain
      );
      startAuthFlow({
        provider: provider,
        config,
        storage: stateStorage,
        nonceGenerator,
      });
    } else {
      throw new Error(`Idp recommended is not valid`);
    }
  }

  if (!signInSwitchFlowEnabled) {
    return <></>;
  }
  return (
    <Main config={config}>
      <ContainerLayout>
        <Header variant={'h2'}>
          {formatMessage(signInSwitchNeededMessages.header, {
            orgName:
              user.organization?.name ??
              formatMessage(signInSwitchNeededMessages.organization),
          })}
        </Header>
        <br />
        <Container
          header={
            <Header variant={'h3'}>
              {formatMessage(signInSwitchNeededMessages.containerTitle)}
            </Header>
          }
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                  }}
                  data-testid={'return-to-signin'}
                >
                  {formatMessage(
                    signInSwitchNeededMessages.returnToSignInPageButton
                  )}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleContinueToIdp();
                  }}
                  data-testid={'continue-to-signin'}
                  variant={'primary'}
                >
                  {formatMessage(signInSwitchNeededMessages.continueButton, {
                    idpToUse: idpToUse,
                  })}
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xs">
              <TextContent>
                {formatMessage(signInSwitchNeededMessages.containerBody, {
                  idpToUse: idpToUse,
                })}
              </TextContent>
            </SpaceBetween>
          </Form>
        </Container>
      </ContainerLayout>
    </Main>
  );
};

export default SignInSwitchNeeded;
