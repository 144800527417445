import { Container, Header } from '@amzn/awsui-components-react';
import { AppConfig } from '../types/app';
import ContainerLayout from './ContainerLayout';
import Main from './Main';
import { useIntl } from 'react-intl';
import identityConsentMessages from '../i18n/identityConsent.messages';
import React from 'react';
import { GandalfUser } from '../types/auth';

const ConsentContainer = ({
  children,
  config,
  user,
}: {
  children: React.ReactNode;
  config: AppConfig;
  user: GandalfUser;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Main config={config}>
      <ContainerLayout>
        <Header data-testid={'join-org-header'}>
          {formatMessage(identityConsentMessages.header, {
            orgName: user.invitation?.organization_name,
          })}
        </Header>
        <br />
        <Container>{children}</Container>
      </ContainerLayout>
    </Main>
  );
};

export default ConsentContainer;
