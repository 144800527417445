import { Link, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { GandalfUser } from '../types/auth';
import identityConsentMessages from '../i18n/identityConsent.messages';
import { T_AND_C_PRIVACY, T_AND_C_TERMS } from '../constants/links';

interface EulaAndConsent {
  isEULAPresent: boolean;
  isConsentRequired: boolean;
}

const IndetityConsentInfo = ({ user }: { user: GandalfUser }) => {
  const { formatMessage } = useIntl();

  const eulaAndConsent = checkConsentOrEULAOnFile(user);

  return (
    <SpaceBetween direction="vertical" size="m">
      <TextContent>
        <h3>{formatMessage(getConsentHeader(eulaAndConsent))}</h3>
      </TextContent>
      <TextContent>
        {formatMessage(getConsentContent(eulaAndConsent), {
          br: <br />,
          b: (str) => <b>{str}</b>,
          tandcLink: (str) => (
            <Link
              href={T_AND_C_TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              {str}
            </Link>
          ),
          privacyLink: (str) => (
            <Link
              href={T_AND_C_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {str}
            </Link>
          ),
          orgName: user.invitation?.organization_name,
        })}
      </TextContent>
    </SpaceBetween>
  );
};

const checkConsentOrEULAOnFile = (user: GandalfUser) => {
  const eulaAndConsent: EulaAndConsent = {
    isEULAPresent: false,
    isConsentRequired: false,
  };

  if (user.eulaStatus) {
    eulaAndConsent.isEULAPresent = true;
  }

  if (user.invitation?.learner_data_consent_required) {
    eulaAndConsent.isConsentRequired = true;
  }

  return eulaAndConsent;
};

const getConsentHeader = (eulaAndConsent: EulaAndConsent) => {
  if (eulaAndConsent.isEULAPresent && eulaAndConsent.isConsentRequired) {
    return identityConsentMessages.organizationDataAccessNoticeHeader;
  } else {
    return identityConsentMessages.termsAndConditionsAndOrganizationDataAccessNoticeHeader;
  }
};

const getConsentContent = (eulaAndConsent: EulaAndConsent) => {
  if (eulaAndConsent.isEULAPresent && eulaAndConsent.isConsentRequired) {
    return identityConsentMessages.organizationDataAccessNoticenBody;
  } else {
    return identityConsentMessages.termsAndConditionsAndOrganizationDataAccessNoticeBody;
  }
};

export default IndetityConsentInfo;
